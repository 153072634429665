<template>
  <v-card flat tile>
    <VListSessionPrincipal class="pt-0" :principal="principal"/>
  </v-card>
</template>

<script>
import VListSessionPrincipal from "../VListSessionPrincipal";
import {SessionRepository} from "../../../../repositories/session-repository";

export default {
  components: {
    VListSessionPrincipal
  },
  props: {
    sessionId: {
      type: String,
      required: true,
      default: undefined
    }
  },
  data() {
    return {
      principal: undefined
    }
  },
  watch: {
    async sessionId(value) {
      this.principal = await this.fetchSessionPrincipal(value);
    }
  },
  async mounted() {
    this.principal = await this.fetchSessionPrincipal(this.sessionId);
  },
  methods: {
    async fetchSessionPrincipal(id) {
      try {
        const {data} = await SessionRepository.findPrincipalById(id);
        return data;
      } catch (error) {
        this.$store.commit('SET_ERROR', error);
        throw error;
      }
    }
  }
}
</script>
