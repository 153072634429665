<template>
  <v-list>
    <v-list-item>
      <v-list-item-action>
        <v-icon>mdi-account</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{ principal.name }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider inset></v-divider>

    <v-list-item>
      <v-list-item-action>
        <v-icon>mdi-card-account-details</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{ principal.kennziffer }}</v-list-item-title>
        <v-list-item-subtitle>Kennziffer</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider inset></v-divider>

    <v-list-item>
      <v-list-item-action>
        <v-icon>mdi-email</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{ principal.email }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider inset></v-divider>

    <v-list-item>
      <v-list-item-action>
        <v-icon>mdi-key</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{ principal.rollen ? principal.rollen.join(', ') : '' }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <template v-if="principal.amtstaetigkeiten">
      <v-divider inset></v-divider>
      <v-list-group
        prepend-icon="mdi-briefcase"
      >
        <template v-slot:activator>
          <v-list-item-title>Amtstätigkeiten</v-list-item-title>
        </template>

        <v-list-group
          v-for="amtstaetigkeit in principal.amtstaetigkeiten"
          :key="amtstaetigkeit.amtstaetigkeitId"
          no-action
          sub-group
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ amtstaetigkeit.amtstitel }}</v-list-item-title>
              <v-list-item-subtitle>{{ amtstaetigkeit.amtstaetigkeitId }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>

          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-account</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ [amtstaetigkeit.title, amtstaetigkeit.vorname, amtstaetigkeit.name].filter(Boolean).join(' ') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider inset></v-divider>

          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-email</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ amtstaetigkeit.email }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider inset></v-divider>

          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-phone</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ amtstaetigkeit.telefon }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider inset></v-divider>

          <v-list-item @click="">
            <v-list-item-action>
              <v-icon>mdi-map-marker</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ amtstaetigkeit.strasseHn }}, {{ amtstaetigkeit.plz }} {{ amtstaetigkeit.ort }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider inset></v-divider>

          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-card-account-details</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ amtstaetigkeit.safeId }}</v-list-item-title>
              <v-list-item-subtitle>SAFE-ID</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list-group>
    </template>
  </v-list>
</template>

<script>
export default {
  props: {
    principal: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    }
  }
}
</script>
