<template>
  <v-container fluid>
    <v-row no-gutters justify="center">
      <v-col cols="12" md="8" xl="6">
        <v-data-table
          disable-pagination
          disable-sort
          :headers="header"
          hide-default-footer
          :items="sessions"
          show-expand
          single-expand
        >
          <template v-slot:item.creationTime="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{ item.creationTime | fromNow }}</span>
              </template>
              <span>{{ item.creationTime | dateTime }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.lastAccessTime="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{ item.lastAccessTime | fromNow }}</span>
              </template>
              <span>{{ item.lastAccessTime | dateTime }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.expiryTime="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{ item.expiryTime | fromNow }}</span>
              </template>
              <span>{{ item.expiryTime | dateTime }}</span>
            </v-tooltip>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-4">
              <VSessionDetails :session-id="item.id"/>
            </td>
          </template>

          <template #footer>
            <div class="v-data-footer">
              <div class="v-data-footer__select">
                Elemente pro Seite:
                <v-select
                  v-model="pagination.size"
                  hide-details
                  :items="pagination.itemsPerPageOptions"
                  @change="pagination.page = 1"
                />
              </div>
              <div class="v-data-footer__pagination">
                {{ sessions.length === 0 ? '–' : 'Seite ' + pagination.page }}
              </div>
              <div class="v-data-footer__icons-before">
                <v-btn
                  icon
                  :disabled="pagination.first"
                  @click="pagination.page--"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
              </div>
              <div class="v-data-footer__icons-after">
                <v-btn
                  icon
                  :disabled="pagination.last"
                  @click="pagination.page++"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment';
import VSessionDetails from "./components/VSessionDetails";
import {SessionRepository} from "../../repositories/session-repository";
import {formatDateTime} from "../../common/lang";

export default {
  components: {
    VSessionDetails
  },
  filters: {
    fromNow(date) {
      return moment(date).fromNow();
    },
    dateTime(date) {
      return formatDateTime(date);
    }
  },
  data() {
    return {
      header: [
        {text: "Nutzer", value: "principalName"},
        {text: "Login", value: "creationTime"},
        {text: "Zugriff", value: "lastAccessTime"},
        {text: "Läuft ab", value: "expiryTime"},
        {value: 'data-table-expand'}
      ],
      pagination: {
        itemsPerPageOptions: [5, 10, 15],
        page: 1,
        size: 5,
        first: true,
        last: false
      },
      sessions: []
    }
  },
  computed: {
    query() {
      return {
        size: this.pagination.size,
        page: this.pagination.page
      }
    }
  },
  watch: {
    query: {
      async handler(value) {
        const result = await this.searchSessions({
          ...value,
          page: value.page - 1
        });
        this.sessions = result.content;
        this.pagination.first = result.first;
        this.pagination.last = result.last;
      },
      immediate: true
    }
  },
  methods: {
    async searchSessions(query) {
      try {
        const {data} = await SessionRepository.search(query);
        return data;
      } catch (error) {
        this.$store.commit('SET_ERROR', error);
        throw error;
      }
    }
  }
}
</script>
